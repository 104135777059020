import { Loader } from "@googlemaps/js-api-loader";

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

addEventListener("direct-upload:initialize", (event) => {
  const { target, detail } = event;
  const { id, file } = detail;
  target.insertAdjacentHTML(
    "beforebegin",
    `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
    </div>
    <span class="direct-upload__filename">${file.name}</span><br>
  `
  );
});

addEventListener("direct-upload:start", (event) => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.remove("direct-upload--pending");
});

addEventListener("direct-upload:progress", (event) => {
  const { id, progress } = event.detail;
  const progressElement = document.getElementById(`direct-upload-progress-${id}`);
  progressElement.style.width = `${progress}%`;
});

addEventListener("direct-upload:error", (event) => {
  event.preventDefault();
  const { id, error } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--error");
  element.setAttribute("title", error);
  /*eslint no-alert: "off"*/
  alert("There was an error uploading - please try again.");
  window.location.reload();
});

addEventListener("direct-upload:end", (event) => {
  const { id } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--complete");
});

const GOOGLE_MAP_DEFAULT_STYLE = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444"
      }
    ]
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100
      },
      {
        lightness: 45
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#dde6e8"
      },
      {
        visibility: "on"
      }
    ]
  }
];

const GOOGLE_MAP_DEFAULT_OPTIONS = {
  center: { lat: 38.0, lng: -97.0 },
  clickableIcons: false,
  fullscreenControl: false,
  keyboardShortcuts: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoom: 4,
  styles: GOOGLE_MAP_DEFAULT_STYLE
};

const MILES_TO_METER_MULTIPLIER = 1609.34;
const STYLE_COLOR_GREEN = "#00d096";
const STYLE_COLOR_GRAY = "#bcbcbc";

function makeMarker(latitude, longitude, map, active) {
  const MARKER_ICON = new google.maps.MarkerImage(
    imagePath("./icons/marker.svg"),
    null,
    null,
    null,
    new google.maps.Size(30, 30)
  );
  const MARKER_INACTIVE_ICON = new google.maps.MarkerImage(
    imagePath("./icons/marker-inactive.svg"),
    null,
    null,
    null,
    new google.maps.Size(30, 30)
  );
  const coords = new google.maps.LatLng(latitude, longitude);
  return new google.maps.Marker({
    position: coords,
    map: map,
    icon: active ? MARKER_ICON : MARKER_INACTIVE_ICON
  });
}

function initOverviewMap(locations, key) {
  if (!key) {
    return false;
  }
  if (!document.getElementById("overview-map")) {
    return false;
  }

  let map;

  if (typeof google === "undefined") {
    const loader = new Loader({ apiKey: key });

    loader.load().then(() => {
      map = new google.maps.Map(
        document.getElementById("overview-map"),
        GOOGLE_MAP_DEFAULT_OPTIONS
      );
      drawMap(map, locations);
    });
  } else {
    map = new google.maps.Map(document.getElementById("overview-map"), GOOGLE_MAP_DEFAULT_OPTIONS);
    drawMap(map, locations);
  }
}

function drawMap(map, locations) {
  const bounds = new google.maps.LatLngBounds();

  locations.forEach((location) => {
    const marker = makeMarker(location[0], location[1], map, location[2]);
    bounds.extend(marker.getPosition());
  });

  if (locations.length > 1) {
    map.fitBounds(bounds);
  }
  map.panToBounds(bounds);
}

function initLocationMap(latitude, longitude, radius, active, key) {
  const mapOptions = {
    center: { lat: latitude, lng: longitude }
  };

  if (typeof google === "undefined") {
    const loader = new Loader({ apiKey: key });

    loader.load().then(() => {
      const map = new google.maps.Map(
        document.getElementById("location-map"),
        Object.assign(mapOptions, GOOGLE_MAP_DEFAULT_OPTIONS)
      );

      drawLocation(map, latitude, longitude, radius, active);
    });
  } else {
    const map = new google.maps.Map(
      document.getElementById("location-map"),
      Object.assign(mapOptions, GOOGLE_MAP_DEFAULT_OPTIONS)
    );

    drawLocation(map, latitude, longitude, radius, active);
  }
}

function drawLocation(map, latitude, longitude, radius, active) {
  makeMarker(latitude, longitude, map);

  const circle = new google.maps.Circle({
    center: new google.maps.LatLng(latitude, longitude),
    fillColor: active ? STYLE_COLOR_GREEN : STYLE_COLOR_GRAY,
    fillOpacity: 0.3,
    strokeWeight: 1,
    strokeColor: active ? STYLE_COLOR_GREEN : STYLE_COLOR_GRAY,
    radius: radius * MILES_TO_METER_MULTIPLIER,
    map: map
  });

  map.fitBounds(circle.getBounds());
  map.panToBounds(circle.getBounds());
}

window.initOverviewMap = initOverviewMap;
window.initLocationMap = initLocationMap;
